@use "src/sedestral/sass/mixins/texts";

.componentCaptcha {
  width: 100%;

  .recaptcha {
    width: 302px;
    height: 76px;
    overflow: hidden;
  }
}