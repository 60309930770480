@use "../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../sedestral/sass/mixins/outline";
@use "../../../../../sedestral/sass/mixins/texts";
@use "../../../../../sedestral/sass/icons/icons";

.globalButtonsSocial {
  .button {
    width: calc(100% - 32px);
    height: 56px;
    align-items: center;
    padding: 0 16px;
    border-radius: 60px;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    margin-bottom: 10px;
    background: var(--grey-sur);

    @include texts.font("semi-bold");
    @include positioning.flex-globally;

    &Fcb {
      color: var(--white);
      background: var(--blue-mep);

      &:hover {
        background: var(--blue-mep-hover);
      }

      .icon {
        filter: var(--icon-white);

        @include icons.ico("black-facebook");
      }

      div[loaderlight] {
        circle {
          stroke: var(--white);
        }
      }
    }

    &Google {
      background: var(--grey-light);
      color: var(--black);

      &:hover {
        background: var(--grey);
      }

      .icon {
        @include icons.ico("colored-google");
      }
    }

    &Twt {
      background: var(--blue-twt);
      color: var(--white);

      &:hover {
        background: var(--blue-twt-black);
      }

      .icon {
        filter: var(--icon-white);
        height: 24px !important;

        @include icons.ico("black-twitter-logo");
      }

      div[loaderlight] {
        circle {
          stroke: var(--white);
        }
      }
    }

    &Link {
      background: var(--blue-twt);
      color: var(--white);

      &:hover {
        background: var(--blue-twt-black);
      }

      .icon {
        filter: var(--icon-white);
        height: 24px !important;

        @include icons.ico("black-twitter-logo");
      }

      div[loaderlight] {
        circle {
          stroke: var(--white);
        }
      }
    }

    .icon {
      width: 29px;
      height: 29px;
      left: 15px;
      position: absolute;
    }
  }
}