@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/buttons";

.componentSiteNone {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;

  .head {
    height: 50px;
    width: 100%;

    .text {
      border-bottom: 3px solid var(--grey-sur);
      width: fit-content;
      font-size: 22px;
      margin-top: 11px;
      margin-left: 15px;

      @include texts.font("extra-bold");
    }
  }

  .content {
    position: absolute;
    height: calc(100% - 62px);
    width: 100%;

    @include positioning.flex-globally;

    .container {
      height: 400px;
      width: 380px;
      margin-top: -100px;

      .illustration {
        height: 120px;
        width: 120px;
        margin-left: calc(50% - 60px);

        @include icons.ico("3d-office");
      }

      .title {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        font-size: 20px;

        @include texts.font("bold");
      }

      .description {
        margin-top: 10px;
        font-size: 14px;
        color: var(--grey-text);
        text-align: center;
      }

      .box {
        padding: 25px;
        width: calc(100% - 50px);
        background-color: var(--blue-str);
        box-shadow: 0 0 0 1px var(--blue-str-shadow);
        border-radius: 14px;
        margin-top: 20px;

        .sitePicker {
          margin-bottom: 5px;
          width: calc(100% - 0px);
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          height: 20px;
          max-height: 40px;
          padding: 15px 0;

          @include texts.font("semi-bold");
          @include positioning.clear;

          &:hover {
            background: var(--blue-str-hover);
          }

          span {
            width: 100%;
            display: block;
            text-align: center;
          }

          svg {
            width: 20px;
            height: 20px;
          }

          .avatarContainer {
            height: 20px;
            width: 20px;
            margin-right: 10px;
            position: relative;
            float: left;
          }

          .name {
            float: left;

            @include texts.font("semi-bold");
          }

          .arrow {
            margin-top: 2px;
            height: 16px;
            width: 16px;
            float: right;

            @include icons.ico("black-bottom-bold");
          }
        }

        .separator {
          height: 2px;
          margin-bottom: 10px;
          width: 100%;
          background: var(--blue-str-hover);
        }

        .create,.logout {
          @include positioning.flex-globally;
          @include buttons.grey;
          @include texts.font("semi-bold");

          position: relative;
          margin-top: 12px;
          padding: 12px;
          height: 16px;
          border-radius: 12px;
          font-size: 14px;
          background: var(--blue-str-hover);

          &:hover {
            background: var(--blue-str-hover-hover);
          }
        }
      }
    }
  }

  .aquarius {
    height: 160px;
    width: 160px;
    position: absolute;
    right: 50px;
    bottom: -15px;

    @include icons.ico("colored-aquarius");
  }

}