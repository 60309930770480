@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentAvatar {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  cursor: pointer;
  background: var(--grey-alpha);

  .imageContainer {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 100%;

    @include positioning.flex-globally;

    .avatar {
      position: absolute;
      //height: 100%;
      width: 100%;
    }

    .icon {
      height: 40%;
      width: 40%;
      filter: var(--icon-white);

      &.entity {
        @include icons.ico("black-user");
      }

      &.role {
        @include icons.ico("black-roles");
      }

      &.helpCenter {
        @include icons.ico("black-help-center");
      }

      &.group {
        @include icons.ico("black-members");
      }

      &.site {
        @include icons.ico("black-company");
      }


      &.siteChannel {
        @include icons.ico("black-livechat");
      }

      &.bot {
        height: 50%;
        width: 50%;

        @include icons.ico("black-bot");
      }

    }

  }

  .pastille {
    height: 20%;
    width: 20%;
    border: var(--white) 2px solid;
    background: var(--red-real);
    top: -1px;
    right: -1px;
    border-radius: 100%;
    position: absolute;

    &.active {
      background: var(--green);
    }

    &.inactive {
      background: var(--red);
    }
  }

  .channelContainer {
    height: 18px;
    width: 18px;
    position: absolute;
    bottom: -2px;
    left: -2px;
    border: 1px solid white;
    border-radius: 100%;
    background: var(--white);
  }

  .typeContainer {
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    border: 2px solid var(--white);
    background: var(--white);
    bottom: -2px;
    right: -2px;
    overflow: hidden;

    .pastilleType {
      position: absolute;
      height: 100%;
      width: 100%;
      font-size: 8px;
      background: var(--grey-sur);

      @include texts.font("bold");
      @include positioning.flex-globally;
    }
  }
}