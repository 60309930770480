.bottom {
  bottom: 10px;
  width: 100%;

  .copyright {
    margin-bottom: 15px;
    width: 100%;
    font-size: 10px;
    text-align: center;
    position: relative;
    color: var(--grey-text);

    a {
      color: var(--grey-text);
      text-decoration: underline;
    }
  }
}