@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";


.componentPresentationScreen {
  position: absolute;
  padding: 0 35px;
  z-index: 15;
  background: var(--white);
  height: 100%;
  width: calc(100% - 70px);

  div[scrollVirtual] {
    div[scrollGrabbed] {
      width: 12px !important;
    }

    div[scrolltrack] {
      right: 10px;
      width: 10px;

      &:hover {
        width: 12px !important;
      }

      div[scrollBar] {
        background: var(--black-alpha);
      }
    }
  }

  .scrollable {
    height: 100%;
    width: 100%;

    .headContainer {
      height: 226px;

      .scrollHead {
        visibility: visible;
      }
    }
  }

  .logoContainer {
    height: 40px;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 10px;

    @include positioning.flex-globally;

    .inboxLogo {
      float: left;
      height: 24px;
      width: 24px;
    }

    .inboxName {
      font-size: 18px;
      margin-left: 8px;
      float: left;
      margin-top: 1px;

      @include texts.font("semi-bold");
    }
  }

  .descriptionContainer {
    text-align: center;
    font-size: 24px;
    max-width: 530px;
    margin-left: calc(50% - 265px);

    @include texts.font("bold");
  }

  .buttonsContainer {
    width: 100%;
    margin-top: 24px;

    @include positioning.flex-globally;

    .startButton {
      background: var(--purple);
      width: fit-content;
      border-radius: 4px;
      padding: 12px 46px;
      cursor: pointer;
      color: var(--white);

      @include positioning.clear;

      &:hover {
        background: var(--purple-dark);
      }

      .text {
        float: left;

        @include texts.font("semi-bold");
      }

      .icon {
        margin-top: 4px;
        margin-left: 16px;
        float: left;
        height: 14px;
        width: 14px;
        filter: var(--icon-white);

        @include icons.ico("black-right-arrow");
      }
    }
  }

  .illustration {
    position: relative;
    width: 100%;
    margin-top: 35px;
    margin-bottom: 60px;

    @include positioning.flex-globally;
  }

  .imageContainer {
    background: var(--grey-sur);
    width: 760px;
    box-shadow: 0 0 50px #00000024;
    margin-left: calc(50% - 380px);
    margin-top: 35px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 60px;

    img {
      width: 100%;
    }

    &.borderNone {
      box-shadow: none;
      background: none;
    }
  }

  .fixed {
    position: fixed;
    width: calc(100% - 61px);
    height: 60px;
    background: var(--white);
    top: -30px;
    z-index: 9;
    left: 61px;
    box-shadow: 0 4px 10px 0 #0000000d;

    .buttonsContainer {
      width: 250px;
      right: 50px;
      float: right;
      top: -16px;
      position: absolute;
    }

    .descriptionContainer {
      display: none;
    }

    .logoContainer {
      width: fit-content;
      margin-left: 40px;
    }
  }
}