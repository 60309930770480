@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/boxs";

.componentSignupForm {
  display: flex;
  flex-direction: column;
  gap: 13px;

  .invitationContainer {
    .invitation {
      padding: 16px;
      border-radius: 14px;

      @include boxs.white;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin: 0;

    .inputContainer {
      height: 42px;
      position: relative;
    }

    .consent {
      position: relative;
      height: fit-content;
      display: flex;
      flex-direction: row;
      gap: 11px;
      align-items: center;

      .checkBoxContainer {
        visibility: visible;
      }

      .label {
        cursor: pointer;
        font-size: 13px;

        @include texts.font("medium");
      }
    }

    .captchaContainer {
      visibility: visible;
    }
  }

  .line {
    margin-top: 3px;
    height: 1px;
    width: 100%;
    background: var(--grey);
  }

  .button {
    width: calc(100% - 32px);
    height: 56px;
    align-items: center;
    padding: 0 16px;
    border-radius: 60px;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    background: var(--grey-sur);

    @include texts.font("semi-bold");
    @include positioning.flex-globally;

    &:hover {
      background: var(--grey-sur-hover);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.9;
      color: var(--grey-text-dark)
    }

    &.continue {
      visibility: visible;
    }

  }
}