@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/buttons";

.componentSignup {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  .left {
    width: 483px;
    height: 100%;
    border-right: 1px solid var(--grey);
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;

    .header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px 32px 0 32px;

      .top {
        display: flex;

        .logo {
          @include buttons.round;

          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          padding: 8px 8px;
          width: fit-content;
          border-radius: 22px;
          margin-left: -8px;

          .capsuleContainer {
            width: 25px;
            height: 25px;
            position: relative;
          }

          .title {
            font-size: 18px;
            @include texts.font("semi-bold");
          }
        }

        .back {
          @include buttons.round;

          display: flex;
          font-size: 14px;
          cursor: pointer;
          border-radius: 30px;
          margin-left: auto;
          padding: 12px;

          @include texts.font("semi-bold");

          .icon {
            height: 14px;
            width: 14px;
            margin-top: 1px;
            margin-right: 10px;

            @include icons.ico("black-left-arrow");
          }
        }
      }

      .names {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .title {
          background: var(--grad-multi);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 32px;

          @include texts.font("extra-bold");
        }

        .description {
          font-size: 16px;

          @include texts.font("semi-bold");
        }
      }
    }

    .formContainer {
      padding: 0 32px 21px 32px;
      height: fit-content;
      position: relative;
    }

    .copyrightContainer {
      width: 100%;
      position: relative;
      margin-bottom: 20px;
    }
  }

  .right {
    width: calc(100% - 484px);
    height: 100%;

    .header {
      width: calc(100% - 24px);
      height: 42px;
      border-bottom: 1px solid var(--grey);
      padding: 11px 12px;
      display: flex;
      flex-direction: row;
      gap: 12px;

      .round {
        @include buttons.round;

        border-radius: 35px;
        padding: 10px 12px 10px 12px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        gap: 7px;
        align-items: center;

        &.selected {
          background: var(--grey);
        }

        .icon {
          width: 22px;
          height: 22px;
        }

        .text {
          font-size: 16px;

          @include texts.font("bold");
        }
      }
    }

    .content {
      position: relative;
      width: 100%;
      height: calc(100% - 68px);
    }
  }
}