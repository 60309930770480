@use "../../../../sedestral/sass/icons/icons";
@use "../../../../sedestral/sass/mixins/texts";
@use "../../../../sedestral/sass/mixins/positioning";

.globalCase {
  display: flex;
  gap: 15px;

  .case {
    border-radius: 15px;
    height: 200px;
    width: 100%;
    border: 2px solid var(--grey-sur);
    transition: 0.15s;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &:hover {
      cursor: pointer;
      border: 2px solid var(--blue);
    }

    &.invitation {
      .icon {
        @include icons.ico("3d-identity");
      }
    }

    &.create {
      .icon {
        @include icons.ico("3d-writing");
      }
    }

    &.articleBlank {
      .icon {
        @include icons.ico("3d-article-blank");
      }
    }

    &.articleAi {
      .icon {
        @include icons.ico("3d-ai-writing");
      }
    }

    &.mini {
      .icon {
        height: 34px;
        width: 34px;
      }
    }

    .icon {
      height: 80px;
      width: 80px;
      margin-bottom: 10px;
    }

    .text {
      text-align: center;
      font-size: 17px;

      @include texts.font("semi-bold");
    }

    .description {
      font-size: 13px;
      text-align: center;
      max-width: 90%;
      color: var(--grey-text-very-dark);
      margin-top: 10px;
      height: 46px;
    }
  }
}