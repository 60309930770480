@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/positioning";

.componentInvitationBanner {
  height: 100%;
  width: 100%;
  position: relative;

  @include positioning.flex-vertically;

  .invitation {
    .avatarContainer {
      height: 20px;
      position: relative;
      width: 20px;
      margin-left: 14px;
      margin-top: 3px;
      float: left;
    }

    .description {
      float: left;
      font-size: 13px;
      margin-top: 4px;
      margin-left: 8px;

      @include texts.font("semi-bold");
    }

    .errorText {
      float: left;
      margin-left: 10px;
      color: var(--red);
      margin-top: 5px;
      font-size: 13px;

      @include texts.font("semi-bold");
    }

    .errorIcon {
      height: 20px;
      width: 20px;
      margin-left: 14px;
      margin-top: 2px;
      float: left;
      filter: var(--icon-red);

      @include icons.ico("black-warning");
    }
  }
}