@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/texts";

.componentRoleNone {
  position: absolute;
  height: 100%;
  width: 100%;

  @include positioning.flex-globally;

  .container {
    margin-top: -100px;

    .icon {
      height: 120px;
      width: 120px;
      margin-left: calc(50% - 60px);
      margin-bottom: 20px;

      @include icons.ico("3d-locked");
    }

    .text {
      font-size: 20px;
      width: 360px;
      text-align: center;

      @include texts.font("bold");
    }

  }

  .colors {
    position: absolute;
    height: 20px;
    overflow: hidden;
    border-radius: 5px;
    width: 600px;
    bottom: 20px;
    left: calc(50% - 300px);

    &.top {
      bottom: unset;
      top: 20px;
    }

    .color {
      height: 100%;
      float: left;
      width: 16.666%;

      &.c1 {
        background: var(--colors-1);
      }

      &.c2 {
        background: var(--colors-2);
      }

      &.c3 {
        background: var(--colors-3);
      }

      &.c4 {
        background: var(--colors-4);
      }

      &.c5 {
        background: var(--colors-5);
      }

      &.c6 {
        background: var(--colors-6);
      }
    }
  }


}