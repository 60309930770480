@use "../../../../sedestral/sass/mixins/positioning";
@use "../../../../sedestral/sass/icons/icons";
@use "../../../../sedestral/sass/extends/buttons";
@use "../../../../sedestral/sass/mixins/texts";

.visualSteps {
  width: 100%;

  .head {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid var(--grey-sur);
    margin-bottom: 10px;

    .arrow {
      height: 26px;
      width: 26px;
      float: left;
      margin-top: -2px;
      margin-right: 5px;

      @include positioning.flex-globally;
      @include buttons.round;

      .icon {
        height: 16px;
        width: 16px;

        @include icons.ico("black-left-arrow");
      }
    }

    .name {
      font-size: 18px;

      @include texts.font("semi-bold");
    }
  }
}